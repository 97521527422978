<template>
    <div>
      <Pane />
  
      <a-card class="container">
        <a-row>
          <a-col :span="24">
            <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">  
              <a-form-model-item>
                <a-input v-model="form.name" placeholder="任务名称" style="width: 150px"></a-input>
              </a-form-model-item>
  
              <a-form-model-item>
                <a-select v-model="form.status" placeholder="任务状态" style="width: 150px">
                  <a-select-option
                    v-for="item in orderStatus"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
  
              <a-form-model-item>
                <a-space>
                  <a-button @click="query" type="primary">查询</a-button>
                  <a-button @click="reset">重置</a-button>
                </a-space>
              </a-form-model-item>
              <a-button @click.prevent="$router.push($route.path + '/add')" type="primary" style="float: right;margin-top: 4px">创建</a-button>
            </a-form-model>
          </a-col>
        </a-row>
  
        <Padding />
        <a-table
          bordered
          :data-source="list"
          :loading="loading"
          @change="onChange"
          :pagination="{
            total,
            current,
            pageSize,
            showTotal: (total) => `共 ${total} 条记录`,
          }"
          rowKey="id"
        >
          <a-table-column title="序号">
            <template slot-scope="text, record, index">
              {{ (current - 1) * pageSize + (index + 1) }}
            </template>
          </a-table-column>
          <a-table-column title="任务名称" data-index="name"/>
          <a-table-column title="任务开始时间" data-index="startDate" />
          <a-table-column title="任务结束时间" data-index="endDate" />
          <a-table-column title="任务状态" align="center">
            <template slot-scope="text">
              <DataDictFinder
                dictType="qualitySafety.orderStatus"
                :dictValue="text.status"
                iconType="badge"
                badgeType="color"
              />
            </template>
          </a-table-column>
          <a-table-column title="备注" data-index="remark" />
          <a-table-column title="创建人" data-index="createBy" />
          <a-table-column title="创建时间" data-index="createAt" />
          <a-table-column title="操作" width="200px">
            <template slot-scope="text">
              <a-space>
                <a
                  v-if="text.status !== 'running'"
                  href="#"
                  @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
                >编辑</a>
                <a
                  v-if="text.status !== 'invalid'"
                  href="#"
                  @click.prevent="invalid(text.id)"
                >作废</a>
                <a
                  v-if="text.status == 'created'"
                  href="#"
                  @click.prevent="start(text.id)"
                >开始</a>
                <a
                  v-if="text.status == 'completed' || text.status == 'reviewing' || text.status == 'approved'"
                  href="#"
                  @click.prevent="$router.push($route.path + '/list?id=' + text.id + '&status=' + text.status)"
                >评估清单</a>
                <a
                  href="#"
                  @click.prevent="$router.push($route.path + '/detail?id=' + text.id)"
                >详情</a>
              </a-space>
            </template>
          </a-table-column>
        </a-table>
      </a-card>
    </div>
  </template>
  
  <script>
  import request from "@/api/request";
  import { mapGetters } from "vuex";
  import organization from "@/mixins/organization";
  
  function fetchList(data) {
    return request({
      url: "/office-service/quality/risk/task/list",
      method: "post",
      data
    });
  }
  function setInvalid(id) {
    return request({
      url: "/office-service/quality/risk/task/invalid/" + id,
    });
  }
  
  function startTask(id) {
    return request({
      url: "/office-service/quality/risk/task/startTask/" + id,
    });
  }

  export default {
    mixins: [organization],
    data() {
      return {
        form: {},
  
        loading: false,
        list: [],
        total: 0,
        current: 1,
        pageSize: 10,
  
        visible: false,
        detail: {}
      };
    },
  
    computed: {
      ...mapGetters("setting", ["findDataDict"]),
      orderStatus() {
        return this.findDataDict("qualitySafety.orderStatus");
      },
    },
  
    mounted() {
      this.getList();
    },
  
    methods: {
      invalid(id) {
        const that = this;
        this.$confirm({
          title: "确认要作废吗？",
          onOk() {
            setInvalid(id).then(() => {
              that.getList();
            });
          }
        });
      },
      start(id) {
        const that = this;
        this.$confirm({
          title: "确认要开始吗？",
          onOk() {
            startTask(id).then(() => {
              that.getList();
            });
          }
        });
      },
      getList() {
        this.loading = true;
        fetchList({
          pageNum: this.current,
          pageSize: this.pageSize,
          ...this.form
        })
          .then(res => {
            if (Array.isArray(res.list)) {
              this.list = res.list;
              this.total = res.totalSize || 0;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
  
      onChange(pagination) {
        this.current = pagination.current;
        this.pageSize = pagination.pageSize;
        this.getList();
      },
  
      query() {
        this.current = 1;
        this.getList();
      },
      reset() {
        this.current = 1;
        this.form = {};
        this.getList();
      },
    }
  };
  </script>
  